import React, { useEffect, useState } from "react"

const COOKIE = "cookies";

export const CookiesContext = React.createContext();

export default ({ children }) => {
  const [accepted, setAccepted] = useState(null)

  useEffect(() => {
    setAccepted(localStorage.getItem(COOKIE));
    // eslint-disable-next-line
  }, []);

  const acceptCookies = (value) => {
    if (value !== null) localStorage.setItem(COOKIE,  typeof value === 'object' ? JSON.stringify(value) : value);
    else localStorage.removeItem(COOKIE);
    setAccepted(value)
  }

  return (
    <CookiesContext.Provider value={{ accepted: accepted !== null, accept: acceptCookies }}>
      {children}
    </CookiesContext.Provider>
  )
};
