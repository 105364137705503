export default {
  es: {
    tabs: {
      consent: "Consentimiento",
      details: "Detalles",
      cookies: "Acerca de las cookies"
    },
    categories: {
      necessary: "Necesarias",
      necessaryExplanation: "Las cookies necesarias son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.",
      preferences: "Preferencias",
      preferencesExplanation: "Las cookies de preferencias permiten a la página web recordar información que cambia la forma en que la página se comporta o el aspecto que tiene, como su idioma preferido o la región en la que usted se encuentra."
    },
    sections: {
      main: `
**Este sitio web utiliza cookies**

Este sitio web utiliza Cookies propias y de terceros para recopilar información con la finalidad técnica, no se recaban ni ceden sus datos de carácter personal sin su consentimiento.
Asimismo, se informa que este sitio web dispone de enlaces a sitios web de terceros con políticas de privacidad ajenas a ARIANY GESTIO SL.`,
      about: `
Las cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario.

La ley afirma que podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el funcionamiento de esta página. Para todos los demás tipos de cookies necesitamos su permiso.

Esta página utiliza tipos diferentes de cookies. Algunas cookies son colocadas por servicios de terceros que aparecen en nuestras páginas.

En cualquier momento puede cambiar o retirar su consentimiento desde la Declaración de cookies en nuestro sitio web.

Obtenga más información sobre quiénes somos, cómo puede contactarnos y cómo procesamos los datos personales en nuestra Política de privacidad.

Al contactarnos respecto a su consentimiento, por favor, indique el ID y la fecha de su consentimiento.
      `
    },
    buttons: {
      accept: "Permitir todas",
      acceptSelected: "Permitir la selección",
      decline: "Denegar"
    },
  },
  en: {
    tabs: {
      consent: "Consent",
      details: "Details",
      cookies: "About cookies"
    },
    categories: {
      necessary: "Necessary",
      necessaryExplanation: "Necessary cookies are those that allow the user to browse through a website, platform or application and to use the different options or services that exist on it.",
      preferences: "Preferences",
      preferencesExplanation: "Preference cookies allow the website to remember information that changes the way the website behaves or looks, such as your preferred language or the region you are in."
    },
    sections: {
      main: `
**This website uses cookies**

This website uses first and third party cookies to collect information for technical purposes, your personal data is not collected or transferred without your consent.
It is also reported that this website has links to third party websites with privacy policies outside ARIANY GESTIO SL.`,
      about: `
Cookies are small text files that websites can use to make the user experience more efficient.

The law states that we may store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.

This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.

You can change or withdraw your consent at any time from the Cookie Statement on our website.

Find out more about who we are, how you can contact us and how we process personal data in our Privacy Policy.

When contacting us regarding your consent, please indicate the ID and date of your consent.
      `
    },
    buttons: {
      accept: "Accept all",
      acceptSelected: "Accept selection",
      decline: "Decline"
    },
  },
  de: {

  }
}
