import React, { useContext, /*useState,*/ useEffect, useState } from 'react';
import { Switch, Box, Button, makeStyles } from "@material-ui/core";
import posed from "react-pose";
import { useLocation } from "react-router";

import { CookiesContext } from '../../wrappers/CookiesWrapper';

import { Markdown } from "../Translator";
import constants from "../../../styles/constants";
import LanguagesContext from "../../wrappers/contexts/LanguagesContext";
import translations from "./translations";

import textLogoColor from "../../../media/logo/textLogoColor.svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const TheBox = posed.div(() => {
  return ({
    visible: {
      opacity: 1,
      applyAtStart: { display: 'block' },
      transition: { duration: 700 }
    },
    invisible: {
      opacity: 0,
      applyAtEnd: { display: 'none' },
      transition: { duration: 700 }
    },
  })
});

const Modal = posed.div(() => {
  return ({
    visible: {
      height: "auto",
      applyAtStart: { display: 'block' },
      transition: { duration: 700 }
    },
    invisible: {
      height: 0,
      applyAtEnd: { display: 'none' },
      transition: { duration: 700 }
    },
  })
});

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: constants.color.white,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    position: "fixed",
    maxWidth: "720px",
    width: "95%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    borderRadius: 8,
    padding: 0
  },
  background: {
    zIndex: 10000,
    position: "fixed",
    overflow: "hidden",
    top: 0,
    height: "100vh",
    width: "100%",
    background: "linear-gradient(180deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0.3) 50%, rgba(255,255,255,1) 95%)"
  },
  tab: {
    flexGrow: 1,
    textAlign: "center",
    padding: "15px 10px",
    cursor: "pointer",
  },
  switchBox: {
    flexGrow: 1,
    textAlign: "center",
    padding: "15px 10px",
    border: "solid #f0f0f0",
    borderWidth: "2px 1px 0",
    margin: 0
  },
  button: {
    borderRadius: ".25rem",
    flexGrow: 1
  }
}));


export default () => {
  const location = useLocation();
  const { accepted, accept } = useContext(CookiesContext);
  const classes = useStyles();
  const { lang = "en" } = useContext(LanguagesContext);
  const [tab, setTab] = useState(0);
  const [preferences, setPreferences] = useState(true);

  const visible = !accepted && location.pathname !== "/cookies";
  useEffect(() => {
    if(visible){
      document.body.style.height = "100vh"
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.height = null
      document.body.style.overflow = null
    }
  }, [visible])

  const Tab = ({ children, index }) =>
    <Box
      className={classes.tab}
      borderBottom={tab === index && 2}
      borderColor="primary"
      onClick={() => setTab(index)}
    >{children}</Box>

  const t = translations[lang] || translations.en;
  return (
    <TheBox className={classes.background} pose={visible ? "visible" : "invisible"}>
      <Modal className={classes.box + " cookies"} pose={visible ? "visible" : "invisible"}>
        <div>
          <div style={{ width: "100%", padding: "15px" }}>
            <img src={textLogoColor} height={10} alt="textLogoColor"/>
          </div>
          <div style={{ border: "solid #f0f0f0", borderWidth: "2px 0" }}>

            <Box borderColor="grey.500" borderBottom={1} style={{ display: "flex", justifyContent: "center" }}>
              {Object.values(t.tabs).map((tab, index) =>
                <Tab key={index} index={index}>{tab}</Tab>
              )}
            </Box>
            <Box style={{ maxHeight: 400, overflow: "auto" }}>
              {[
                // Consent
                <div>
                  <div style={{ padding: "15px" }}><Markdown children={t.sections.main}/></div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormControlLabel
                      className={classes.switchBox}
                      value="top"
                      control={<Switch color="primary" disabled />}
                      label={t.categories.necessary}
                      labelPlacement="top"
                    />
                    <FormControlLabel
                      className={classes.switchBox}
                      value="top"
                      control={
                        <Switch
                          color="primary" checked={preferences}
                          onChange={(e, v) => setPreferences(v)}
                        />
                    }
                      label={t.categories.preferences}
                      labelPlacement="top"
                    />
                  </div>
                </div>,
                // Preferences
                <div style={{ padding: 15}} >
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <div style={{ fontWeight: "bold" }}>{t.categories.necessary}</div>
                    <Switch color="primary" disabled />
                  </Box>
                  <div>{t.categories.necessaryExplanation}</div>
                  <hr style={{ opacity: .3, margin: "15px 0" }}/>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <div style={{ fontWeight: "bold" }}>{t.categories.preferences}</div>
                    <Switch
                      color="primary" checked={preferences}
                      onChange={(e, v) => setPreferences(v)}
                    />
                  </Box>
                  <div>{t.categories.preferencesExplanation}</div>

                </div>,
                // About cookies
                <div style={{ padding: "15px" }}><Markdown children={t.sections.about}/></div>
              ][tab]}
            </Box>
          </div>

          <div style={{ padding: "15px", display: "flex", justifyContent: "center", gap: "10px" }}>
            <Button id="exception" color={"primary"} className={classes.button} variant="outlined" onClick={() => accept(false)}>
              <div>
                {t.buttons.decline}
              </div>
            </Button>
            <Button id="exception" color={"primary"} className={classes.button} variant="outlined" onClick={() => accept({ necessary: true, preferences })}>
              <div>
                {t.buttons.acceptSelected}
              </div>
            </Button>
            <Button id="exception" color={"primary"} className={classes.button + " textWhite"} variant="contained" onClick={() => accept(true)}>
              <div>
                {t.buttons.accept}
              </div>
            </Button>
          </div>

        </div>
      </Modal>
    </TheBox>
  )
};
